import Image from 'next/image';

export default function MainPage() {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <Image
        src="/assets/img/40/circular-spinner.svg"
        width={100}
        height={100}
        className="animate-spin"
        alt=""
      />
    </div>
  );
}
